"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FlagDefinition = exports.StripeWithHeight = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const License_enum_1 = require("../util/License.enum");
const AuthorAttribution_1 = require("./AuthorAttribution");
const ItemSource_1 = require("./ItemSource");
const MoreInfo_1 = require("./MoreInfo");
class StripeWithHeight {
}
__decorate([
    class_validator_1.IsString(),
    __metadata("design:type", String)
], StripeWithHeight.prototype, "color", void 0);
__decorate([
    class_validator_1.IsNumber(),
    class_validator_1.Max(1),
    class_validator_1.Min(0),
    __metadata("design:type", Number)
], StripeWithHeight.prototype, "height", void 0);
exports.StripeWithHeight = StripeWithHeight;
/**
 * The data about an actual flag design.
 * See FlagFileDefinition for the broader flag definition,
 * which can include aliases to other flags.
 */
class FlagDefinition {
}
__decorate([
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => ItemSource_1.ItemSource),
    __metadata("design:type", ItemSource_1.ItemSource)
], FlagDefinition.prototype, "source", void 0);
__decorate([
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => AuthorAttribution_1.AuthorAttribution),
    __metadata("design:type", AuthorAttribution_1.AuthorAttribution)
], FlagDefinition.prototype, "author", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.IsEnum(License_enum_1.License),
    __metadata("design:type", String)
], FlagDefinition.prototype, "license", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.IsString(),
    __metadata("design:type", String)
], FlagDefinition.prototype, "based on", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.IsString({
        each: true,
    }),
    __metadata("design:type", Array)
], FlagDefinition.prototype, "stripes", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => StripeWithHeight),
    __metadata("design:type", Array)
], FlagDefinition.prototype, "uneven stripes", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => MoreInfo_1.MoreInfo),
    __metadata("design:type", Array)
], FlagDefinition.prototype, "info", void 0);
exports.FlagDefinition = FlagDefinition;
