"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityFileDefinition = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const TextBlock_1 = require("./content-util/TextBlock");
/**
 * The source index files describing each identity.
 *
 * See identities/lesbian/index.yaml.
 */
class IdentityFileDefinition {
}
__decorate([
    class_validator_1.IsString(),
    class_validator_1.MinLength(2),
    __metadata("design:type", String)
], IdentityFileDefinition.prototype, "name", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.IsBoolean(),
    __metadata("design:type", Boolean)
], IdentityFileDefinition.prototype, "homepage", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => TextBlock_1.TextBlock),
    __metadata("design:type", TextBlock_1.TextBlock)
], IdentityFileDefinition.prototype, "summary", void 0);
__decorate([
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => TextBlock_1.TextBlock),
    __metadata("design:type", TextBlock_1.TextBlock)
], IdentityFileDefinition.prototype, "description", void 0);
exports.IdentityFileDefinition = IdentityFileDefinition;
