"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Remix = exports.RemixPage = exports.RemixPageType = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const License_enum_1 = require("../util/License.enum");
var RemixPageType;
(function (RemixPageType) {
    RemixPageType["ARTICLE"] = "article";
})(RemixPageType = exports.RemixPageType || (exports.RemixPageType = {}));
class RemixPage {
}
__decorate([
    class_validator_1.IsEnum(RemixPageType),
    __metadata("design:type", String)
], RemixPage.prototype, "type", void 0);
__decorate([
    class_validator_1.IsString(),
    __metadata("design:type", String)
], RemixPage.prototype, "name", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.IsUrl(),
    __metadata("design:type", String)
], RemixPage.prototype, "url", void 0);
exports.RemixPage = RemixPage;
/**
 * Defines an external source that was used for some or all of the content of a section -
 * sources _must_ be licensed correctly to be used (e.g. Creative Commons, etc.)
 */
class Remix {
}
__decorate([
    class_validator_1.IsEnum(License_enum_1.License),
    __metadata("design:type", String)
], Remix.prototype, "license", void 0);
__decorate([
    class_transformer_1.Type(() => RemixPage),
    class_validator_1.ValidateNested(),
    class_validator_1.IsDefined(),
    __metadata("design:type", RemixPage)
], Remix.prototype, "page", void 0);
exports.Remix = Remix;
