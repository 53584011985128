"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompiledIdentityIndexFile = void 0;
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const CompiledBriefFlag_1 = require("./compiled-util/CompiledBriefFlag");
const CompiledTextBlock_1 = require("./compiled-util/CompiledTextBlock");
class CompiledIdentityIndexFile {
}
__decorate([
    class_validator_1.IsString(),
    __metadata("design:type", String)
], CompiledIdentityIndexFile.prototype, "id", void 0);
__decorate([
    class_validator_1.IsString(),
    class_validator_1.MinLength(2),
    __metadata("design:type", String)
], CompiledIdentityIndexFile.prototype, "name", void 0);
__decorate([
    class_validator_1.ValidateNested(),
    class_validator_1.IsDefined(),
    class_transformer_1.Type(() => CompiledTextBlock_1.CompiledTextBlock),
    __metadata("design:type", CompiledTextBlock_1.CompiledTextBlock)
], CompiledIdentityIndexFile.prototype, "description", void 0);
__decorate([
    class_validator_1.IsOptional(),
    class_validator_1.ValidateNested(),
    class_transformer_1.Type(() => CompiledBriefFlag_1.CompiledBriefFlag),
    __metadata("design:type", CompiledBriefFlag_1.CompiledBriefFlag)
], CompiledIdentityIndexFile.prototype, "defaultFlag", void 0);
exports.CompiledIdentityIndexFile = CompiledIdentityIndexFile;
